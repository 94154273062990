.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px;
  border-bottom: 0.5px solid black;
  padding: 10px 0;
}
.profileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.profileContainer * {
  margin-right: 10px;
}
.profileContainer img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.profileContainer h3 {
  margin: 0;
  margin-left: 10px;
}
.profileData {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.profileData img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.weatherCard {
  font-family: Hind;
  margin: 10px 0;
  background: linear-gradient(90deg, #201c1c, #31671e);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 13.09px;
  width: 85vw;
  max-width: 374.25px;
  height: 210.58px;
  font-size: 15.28px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}
.weatherCard * {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.weatherCard .DateAndTimeContainer {
  justify-content: space-between;

  text-align: left;
}
.weatherIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.weatherCard .weatherIcon .weatherData {
  display: flex;
  margin-left: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.weatherData .statusTag {
  font-size: 22px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}

/* // weatherStats Card */

.weatherStatsCard {
  background-color: #fafafa;
  margin: 10px;
  width: 40%;
  gap: 15px;
  max-width: 324.85px;
  height: 94.35px;
  border-radius: 16.45px;
  border: 0.29px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.weatherData {
  display: flex;
  gap: 2px;
  flex-direction: column;
}
.weatherStatsCard .data {
  font-size: 19.19px;
  font-weight: 700;
}
.graphContainer {
  margin-top: 20px;
  min-width: 200px;
  max-width: 90vw;
  border-radius: 27.17px;
  padding: 15px;
  background: #fafafa;
  box-shadow: 0 10px 10px #d7d5d5;
}
.graphHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.statsTitle {
  text-transform: capitalize;
}
