.LoginMainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
}
.fonrmContainer{
    margin-top: -10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginInputContainer{
    display: flex;
    flex: 1;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    position: relative;
}
.LoginButton{
    margin-top: 20px;
    background: linear-gradient(0deg, #2C511D, #2C511D);
    color:white;
    width: 176px;
height: 55px;
border-radius: 10px;
border: 1px;
font-family: Outfit;
font-size: 18px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: center;    
}
.BannerLogo{

}
.phoneNumberContainer{
    padding: 10px;
    text-align: center;
    align-items:center;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}
.phoneNumberContainer h1{
    margin:  0;
}

@media(max-width: 900px){

}