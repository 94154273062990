/* General Styles */
h2,
h4 {
  margin: 0;
}

h2 {
  margin-top: 20px;
}

/* Weather Stats Flexbox */
.weatherStats {
  margin-left: 10px;
  min-width: 250px;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  flex: 1;
}

/* Weather Card and Stats Container */
.weatherCardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

/* Graphs Section */
.graphs {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

/* Date Display */
.dateDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  gap: 10px;
}

.dateDisplay button {
  border: none;
  background-color: transparent;
  font-size: 24px;
  color: #31671e;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.3s ease;
}

.dateDisplay button:hover {
  transform: scale(1.2);
}

/* Report Header */
.RepordHeader h2 {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

/* Media Queries for Mobile */
@media (max-width: 700px) {
  .weatherStats {
    margin-left: 0;
    justify-content: center;
  }

  .weatherCardContainer {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .graphs {
    flex-direction: column;
    gap: 15px;
  }

  .dateDisplay button {
    font-size: 18px;
  }
}
